<template>
  <div
    ref="root"
    class="bonuses-timer"
    :class="`bonuses-timer--${props.size || 'sm'}`"
    :style="{ width: `${timerWidth}px` }"
  >
    <span v-show="Number(days) > 0" class="bonuses-timer__days">
      {{ days
      }}{{ getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'bonuses.timerDaysLabel') }}
    </span>

    <span class="bonuses-timer__digit">{{ format(hours) }}</span>
    <span class="bonuses-timer__dots">:</span>
    <span class="bonuses-timer__digit">{{ format(minutes) }}</span>
    <span class="bonuses-timer__dots">:</span>
    <span class="bonuses-timer__digit">{{ format(seconds) }}</span>
  </div>
</template>

<script setup lang="ts">
  const props = defineProps<{
    expiredAt: string;
    hideLabels?: boolean;
    size?: 'sm' | 'md';
  }>();

  const { globalComponentsContent, defaultLocaleGlobalComponentsContent } = useGlobalStore();
  const { days, hours, minutes, seconds, isAlmostDone, startTimer } = useTimer();
  const { getContent } = useProjectMethods();
  const format = (value: string | number): number | string => (Number(value) < 10 ? `0${value}` : value);
  const emit = defineEmits(['time-up']);

  const timerWidth = ref(0);
  const root = ref<HTMLElement | null>(null);
  const setTimerWidth = (): void => {
    const timerScrollWidth = root.value?.scrollWidth;
    timerWidth.value = timerScrollWidth ? timerScrollWidth + 3 : 0;
  };

  watch(isAlmostDone, value => {
    if (value) emit('time-up');
  });

  const mounted = ref(false);
  watch(days, async () => {
    if (!mounted.value) return;
    await nextTick();
    setTimerWidth();
  });

  onMounted(async () => {
    startTimer(props.expiredAt);
    await nextTick();
    setTimerWidth();
    mounted.value = true;
  });
</script>

<style src="~/assets/styles/components/bonuses/timer.scss" lang="scss" />
